import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import bigDecimal from "js-big-decimal";
import dayjs from "dayjs";
import debounce from "debounce";
import { GET_PAYMENTS } from "@/graphql/payshipping";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE } from "@/config";
export default {
  name: "shippay_bill",
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "รายการใบส่งพัสดุ"
    }]);
  },
  created: function created() {
    document.title = "\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23\u0E43\u0E1A\u0E2A\u0E48\u0E07\u0E1E\u0E31\u0E2A\u0E14\u0E38 | ".concat(WEBSITE);
  },
  data: function data() {
    return {
      page: 1,
      pageSize: 10,
      status: "",
      search: ""
    };
  },
  methods: {
    getImportPrice: function getImportPrice(item) {
      return item.amount - (item.logistics.logisticsServicePrice + item.logistics.price + item.minimumPrice);
    },
    debounceInput: debounce(function (e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    getStatusColor: function getStatusColor(status) {
      switch (status) {
        case -1:
        case -10:
          return "default";

        case 0:
          return "warning";

        case 22:
          return "primary";

        case 21:
        case 11:
        case 12:
          return "warning";

        case 101:
        case 102:
          return "success";

        default:
          return "primary";
      }
    },
    format_datetime: function format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    },
    format_number: function format_number(val) {
      var decimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      return bigDecimal.round(val, decimal);
    },
    format_comma: function format_comma(val) {
      return Number(val).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
  },
  apollo: {
    payshippings: {
      query: GET_PAYMENTS,
      variables: function variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search,
          status: this.status
        };
      }
    }
  }
};